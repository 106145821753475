import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';

function App() {
  const [count, setCount] = useState([]);
  const [rate, setRate] = useState([]);

  useEffect(() => {
    //const URL = "https://api-obisoft.bubblelab.ru/api/router/count";//surgut
    const URL = "https://api-obisoft.bubblelab.ru/api/router/counthm";//khantymansiysk
    //const URL = "https://api-obisoft.bubblelab.ru/api/router/countnv";//nizhnevartovsk

    axios.get(URL)
    .then(response => {
      setCount(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    //const URL = "https://api-obisoft.bubblelab.ru/api/router/rate";//surgut
    const URL = "https://api-obisoft.bubblelab.ru/api/router/ratehm";//khantymansiysk
    //const URL = "https://api-obisoft.bubblelab.ru/api/router/ratenv";//nizhnevartovsk

    axios.get(URL)
    .then(response => {
      setRate(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <>
          <div>
            <img src="/qrhm.gif" className="qr"/>
          </div>
          <div className="logotypes">
            <img className="logo-1" src="/logo-1.jpg" />
            <img className="logo-2" src="/logo-2.jpg" />
          </div>
          <h3 className="count_header mb-2 mt-1 text-center">г. Ханты-Мансийск</h3>
          <h3 className="count_header mb-2 mt-1 text-center">ТОП ШКОЛ ПО СДАЧЕ СЫРЬЯ</h3>
          <div>
            {
              count === null ? 
              <p>Загрузка...</p> 
              : 
              <div className="pt-2 pb-5">
                <h5 className="count_header text-center pt-sm-2 pt-2 pb-sm-4 pb-4">ЗА ПЕРИОД АКЦИИ ПРИНЯТО СЫРЬЯ</h5>
                <div className="d-flex flex-sm-row flex-column justify-content-center pt-2">
                  <div className="px-4">
                    <h2 style={{borderColor: '#7EBC89'}} className="count_number text-center">{count?.Стекло?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</h2>
                    <p className="count_text text-center">СТЕКЛО, кг</p>
                    <hr style={{backgroundColor: '#7EBC89'}} className="count_hr" />
                  </div>
                  <div className="px-4">
                    <h2 style={{borderColor: '#8cb9e2'}} className="count_number text-center">{count?.Макулатура?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</h2>
                    <p className="count_text text-center">МАКУЛАТУРА, кг</p>
                    <hr style={{backgroundColor: '#8cb9e2'}} className="count_hr" />
                  </div>
                  <div className="px-4">
                    <h2 style={{borderColor: '#ffd34c'}} className="count_number text-center">{count?.Пластик?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</h2>
                    <p className="count_text text-center">ПЛАСТИК, кг</p>
                    <hr style={{backgroundColor: '#ffd34c'}} className="count_hr" />
                  </div>
                  <div className="px-4">
                    <h2 style={{borderColor: '#FE5D26'}} className="count_number text-center">{count?.Металл?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</h2>
                    <p className="count_text text-center">МЕТАЛЛ, кг</p>
                    <hr style={{backgroundColor: '#FE5D26'}} className="count_hr" />
                  </div>
                  <div className="px-4">
                    <h2 style={{borderColor: '#000'}} className="count_number text-center">{count?.Батарейки?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</h2>
                    <p className="count_text text-center">БАТАРЕЙКИ, кг</p>
                    <hr style={{backgroundColor: '#000'}} className="count_hr" />
                  </div>
                  <div className="px-4">
                    <h2 style={{borderColor: '#802392'}} className="count_number text-center">{count?.Крышки?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</h2>
                    <p className="count_text text-center">КРЫШКИ, кг</p>
                    <hr style={{backgroundColor: '#802392'}} className="count_hr" />
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="mr-2 ">
            {rate.map((rate) => {
              return (
              rate === null ? 'Загрузка..' :
                  <div className='' key={rate.school}>
                    <Row className="spacings">
                      <Col>
                        <div className="d-flex flex-sm-row flex-column">
                          <div className="name">{rate.school} <strong>	&nbsp;	&nbsp;	&nbsp;</strong></div>
                          <div className="multiline" style={{
                            width: "700px"
                          }}>
                          <div className="miniBar">
                            <div className="miniBarProgress" style={{left: 0, width: (rate.groups.Стекло * 100) / rate.groups.Итого + '%', backgroundColor: '#7EBC89'}}></div>
                            <div className="miniBarProgress" style={{left: (rate.groups.Стекло * 100) / rate.groups.Итого + '%', width: (rate.groups.Макулатура * 100) / rate.groups.Итого + '%', backgroundColor: '#8cb9e2'}}></div>
                            <div className="miniBarProgress" style={{left: (((rate.groups.Стекло * 100) / rate.groups.Итого) + ((rate.groups.Макулатура * 100) / rate.groups.Итого)) + '%', width: (rate.groups.Пластик * 100) / rate.groups.Итого + '%', backgroundColor: '#ffd34c'}}></div>
                            <div className="miniBarProgress" style={{left: (((rate.groups.Стекло * 100) / rate.groups.Итого) + ((rate.groups.Макулатура * 100) / rate.groups.Итого) + ((rate.groups.Пластик * 100) / rate.groups.Итого)) + '%', width: (rate.groups.Металл * 100) / rate.groups.Итого + '%', backgroundColor: '#FE5D26'}}></div>
                            <div className="miniBarProgress" style={{left: (((rate.groups.Стекло * 100) / rate.groups.Итого) + ((rate.groups.Макулатура * 100) / rate.groups.Итого) + ((rate.groups.Пластик * 100) / rate.groups.Итого) + ((rate.groups.Металл * 100) / rate.groups.Итого)) + '%', width: (rate.groups.Батарейки * 100) / rate.groups.Итого + '%', backgroundColor: '#000'}}></div>
                            <div className="miniBarProgress" style={{left: (((rate.groups.Стекло * 100) / rate.groups.Итого) + ((rate.groups.Макулатура * 100) / rate.groups.Итого) + ((rate.groups.Пластик * 100) / rate.groups.Итого) + ((rate.groups.Металл * 100) / rate.groups.Итого) + ((rate.groups.Батарейки * 100) / rate.groups.Итого)) + '%', width: (rate.groups.Крышки * 100) / rate.groups.Итого + '%', backgroundColor: '#802392'}}></div>
                            <div className="score miniBarProgressText" style={{left: (((rate.groups.Стекло * 100) / rate.groups.Итого) + ((rate.groups.Макулатура * 100) / rate.groups.Итого) + ((rate.groups.Пластик * 100) / rate.groups.Итого) + ((rate.groups.Металл * 100) / rate.groups.Итого) + ((rate.groups.Батарейки * 100) / rate.groups.Итого) + ((rate.groups.Крышки * 100) / rate.groups.Итого)) +'%'}}>{rate?.groups?.Итого.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
              </div>
              )
            })}
          </div>
    </>
  );
}

export default App;
